import { __awaiter } from "tslib";
import React, { useState, useEffect, useRef } from 'react';
import { useLang } from '@@Hooks/useLang';
import { getProductLink } from '@@Storefront/utils/get-product-link';
import { PriceUtils, ThumbnailUtils } from '@grupakmk/libstorefront';
import { Link } from 'react-router-dom';
import { Icon } from '@@Components/Icon/Icon';
import { dispatch } from '@@Redux/dispatch';
import { addProductToCart } from '@@Redux/thunks/cart.thunks';
import { ProductBadges } from '@@Components/ProductBadges';
import { ResponsiveMatcher } from '@@Components/ResponsiveMatcher';
import { ProductContainer, ProductName, TextContainer, PriceContainer, Price, StyledButton, OldPrice, ButtonsWrapper, ContentContainer, ProductImage, } from '@@Components/ProductTile/styled';
import GTMEvents from '@@Common/gtm';
import useOnScreen from '@@Hooks/useOnScreen';
export const ProductTile = ({ product, onAskAboutAvailabilityClick, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const { translate } = useLang();
    const getProductImage = (product) => ThumbnailUtils.getImageSrc({
        image: product.image,
        width: 600,
        height: 320,
        entity: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef();
    const isVisible = useOnScreen(ref, 0.75);
    useEffect(() => {
        if (isVisible) {
            try {
                GTMEvents.queueViewedItem(product);
            }
            catch (error) { }
        }
    }, [isVisible]);
    const addToCart = (product) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield dispatch(addProductToCart(product));
        setIsLoading(false);
    });
    const isDiscount = !!(((_b = (_a = product === null || product === void 0 ? void 0 : product.prices) === null || _a === void 0 ? void 0 : _a.final_price) === null || _b === void 0 ? void 0 : _b.gross) && ((_d = (_c = product === null || product === void 0 ? void 0 : product.prices) === null || _c === void 0 ? void 0 : _c.final_price) === null || _d === void 0 ? void 0 : _d.net) &&
        ((_f = (_e = product === null || product === void 0 ? void 0 : product.prices) === null || _e === void 0 ? void 0 : _e.final_price) === null || _f === void 0 ? void 0 : _f.gross) < ((_h = (_g = product === null || product === void 0 ? void 0 : product.prices) === null || _g === void 0 ? void 0 : _g.price) === null || _h === void 0 ? void 0 : _h.gross));
    const askAboutAvailability = () => {
        if (onAskAboutAvailabilityClick) {
            onAskAboutAvailabilityClick(product);
        }
    };
    const askAboutAvailabilityBtn = (React.createElement(StyledButton, { "aria-label": "Ask for availability", onClick: askAboutAvailability }, translate('headings.ask_about_availability')));
    const withStockButtons = (product === null || product === void 0 ? void 0 : product.type_id) === 'configurable' ? (React.createElement(Link, { to: getProductLink(product, ''), title: "Product configurable button" },
        React.createElement(StyledButton, { "aria-label": "Add configurable product to cart" }, translate('actions.checkout_variants')))) : (React.createElement(ButtonsWrapper, null,
        React.createElement(Link, { to: getProductLink(product, ''), title: "Product button" },
            React.createElement(StyledButton, { "aria-label": "View product" }, translate('common.check_out'))),
        React.createElement(StyledButton, { "aria-label": "Add product to cart", disabled: isLoading ||
                product.stock.is_in_stock === false ||
                product.stock.qty === 0, loading: isLoading, onClick: () => addToCart(product) },
            React.createElement(Icon, { icon: "cart", width: 30, height: 24 }))));
    const buttonsSection = ((_j = product === null || product === void 0 ? void 0 : product.stock) === null || _j === void 0 ? void 0 : _j.is_in_stock) ? withStockButtons
        : askAboutAvailabilityBtn;
    return (React.createElement(React.Fragment, null, product && (React.createElement(ProductContainer, { "data-cy": "product tile", ref: ref },
        React.createElement(ContentContainer, null,
            React.createElement(ProductBadges, { product: product, margin: '-5px 5px 0 -5px' }),
            React.createElement(Link, { to: getProductLink(product, ''), "data-cy": "product image tile", title: product.name },
                React.createElement(ResponsiveMatcher, { desktop: React.createElement(ProductImage, { src: getProductImage(product), alt: product.image_label || product.name, height: "160", loading: "lazy" }), mobile: React.createElement(ProductImage, { src: getProductImage(product), alt: product.image_label || product.name, height: "130", loading: "lazy" }), mobileBreakpoint: 550 })),
            React.createElement(TextContainer, null,
                React.createElement(Link, { to: getProductLink(product, ''), title: product.name },
                    React.createElement(ProductName, null, product.name)),
                React.createElement(PriceContainer, null,
                    React.createElement(Price, null, ((_l = (_k = product === null || product === void 0 ? void 0 : product.prices) === null || _k === void 0 ? void 0 : _k.final_price) === null || _l === void 0 ? void 0 : _l.gross) && ((_o = (_m = product === null || product === void 0 ? void 0 : product.prices) === null || _m === void 0 ? void 0 : _m.final_price) === null || _o === void 0 ? void 0 : _o.net) && ((_p = PriceUtils.getFormattedPrice(product.prices.final_price.net, product.prices.final_price.gross)) === null || _p === void 0 ? void 0 : _p.price_incl_tax)),
                    isDiscount && (React.createElement(OldPrice, null, (_q = PriceUtils.getFormattedPrice(product.prices.price.net, product.prices.price.gross)) === null || _q === void 0 ? void 0 : _q.price_incl_tax))))),
        buttonsSection))));
};
