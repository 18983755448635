import styled from 'styled-components';
import Button from '@@Components/Buttons/Button';
import { Heading } from '@@Components/Heading';
export const ProductCardContainer = styled.div `
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  @media (max-width: 1000px) {
    flex-flow: column;
  }
`;
export const ProductCardInfo = styled.div `
  flex-grow: 2;
  padding: 30px 0 0 25px;
  width: calc(100% - 670px);
  ${(props) => props.removePaddingTop && 'padding-top: 0;'}
  @media (max-width: 1000px) {
    padding: 0;
    width: 100%;
  }
`;
export const ProductCardPrices = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 1000px) {
    margin-top: 10px;
  }
  align-items: flex-start;
`;
export const ProductCardMainPricesWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;
export const ProductCardPrice = styled.h1 `
  font-size: 30px;
  color: ${(props) => props.theme.colors.primary};
  line-height: 37px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 98%;
  text-overflow: ellipsis;
  @media (max-width: 1135px) {
    font-size: 22px;
    line-height: 32px;
  }
`;
export const ProductCardOriginalPrice = styled.p `
  text-decoration: line-through;
  white-space: nowrap;
  overflow: hidden;
  max-width: 98%;
  text-overflow: ellipsis;
  margin: 0 0 0 15px;
`;
export const ProductCardNetPrice = styled.p `
  color: #525252;
  white-space: nowrap;
  overflow: hidden;
  max-width: 98%;
  text-overflow: ellipsis;
`;
export const ProductCardSpecs = styled.ul `
  margin-bottom: 20px;
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid #bababa;
    }
    padding: 15px 0;
  }
`;
export const StyledButton = styled(Button) `
  width: 100%;
  @media (max-width: 450px) {
    width: 100%;
    max-width: unset;
  }
`;
export const InfoIcon = styled.div `
  padding-left: 5px;
`;
export const PopupButtons = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 470px;
  > button:not(:first-child) {
    margin-top: 10px;
  }
  > * {
    height: 42px;
  }
`;
export const ProductGalleryWrapper = styled.div `
  width: 670px;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;
export const LowestPriceWatermark = styled.div `
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
  z-index: 11;
  @media (max-width: 1000px) {
    width: 80px;
  }
`;
export const WatermarkImage = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const QuantityInputWrapper = styled.div `
  > div {
    width: 125px;
    height: 42px;
    @media (max-width: 1135px) {
      width: 100%;
    }
  }
`;
export const DeliveryInfo = styled.p `
  margin-top: 5px;
`;
export const QuantityAndButtonWrapper = styled.div `
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  > button {
    margin-left: 15px;
  }
  @media (max-width: 1135px) {
    flex-direction: column;
    height: auto;
    max-width: 470px;
    > button {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;
export const QuantityAndButtonsWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const ConfigurationBox = styled.div `
  border-top: 1px solid #d8d8d8;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  > div:not(:first-child) {
    margin-top: 15px;
  }
`;
export const ConfigurationOptionsContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const ConfigurationOptionsLabel = styled.p `
  font-weight: bold;
  margin: 0 5px 0 0;
`;
export const ConfigurationOptionsVariants = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
`;
export const ConfigurationVariant = styled.button `
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.isUnavailable && !props.isActive
    ? '#717171'
    : props.isActive
        ? props.theme.colors.primary
        : '#000000'};
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 9px;
  border: 1px solid #cecece;
  ${(props) => props.isActive &&
    `
    font-weight: 600;
    border: 1px solid #000;
  `};
  display: flex;
  align-items: center;

  ${(props) => props.isUnavailable &&
    `
    background-color: #F6F6F6;
    color: #939393;
  `}

  svg {
    margin-right: 5px;
  }
`;
export const ConfigurationColorVariantWrapper = styled.div `
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.isActive &&
    `
    border: 1px solid #BABABA;
  `}
  margin-right: 8px;
`;
export const ConfigurationColorVariant = styled.button `
  padding: 0;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color};
  ${(props) => (!props.color || props.color === '#ffffff') &&
    `
    border: 1px solid #717171;
  `}
`;
export const ColorValueLabel = styled.p `
  color: ${(props) => props.theme.colors.primary};
  font-weight: 600;
  margin: 0;
`;
export const LabelsWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
`;
export const StyledHeading = styled(Heading) `
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  ${(props) => props.hoverUnderline &&
    `
    &:hover {
      text-decoration: underline;
    }
  `}
`;
export const ChartButton = styled.button `
  text-decoration: underline;
  padding: 0;
`;
export const CodesContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  max-width: 440px;
  padding-bottom: 10px;
  > div:first-child {
    margin-right: 15px;
  }
`;
export const CodeWrapper = styled.div `
  display: flex;
  font-size: 13px;
  line-height: 20px;
  color: #525252;
`;
export const CodeLabel = styled.p `
  font-weight: 500;
  margin: 0 5px 0 0;
`;
export const CodeValue = styled.p `
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;
export const ProductDiscountSavings = styled.div `
  display: inline-flex;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 10px;
  padding: 1px 5px;
  font-style: italic;
`;
export const OmnibusPrice = styled.span `
  color: #848484;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 15px;
`;
export const DeliveryTime = styled.div `
  margin-top: 5px;
  padding: 8px 10px;
  background-color: ${(props) => (props.warning ? '#FCE8E9' : '#EFEFEF')};
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 315px;
  & .delivery-tip {
    text-transform: none;
    max-width: 320px;
    padding: 10px;
  }
`;
export const IconWrapper = styled.div `
  min-width: 18px;
`;
export const Divider = styled.div `
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;
