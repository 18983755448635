import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const ProductContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.input.borderColors.default};
  padding: 10px;
  height: 100%;
  width: 100%;
  max-height: 330px;
  position: relative;
  ${(props) => props.theme.mixins.mobile('padding-bottom: 10px;')};
  &:hover {
    border-color: ${(props) => props.theme.input.borderColors.hover};
  }
`;
export const LowestPriceWatermark = styled.div `
  position: absolute;
  top: 5px;
  right: 5px;
  width: 80px;
  z-index: 11;
`;
export const WatermarkImage = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const ProductName = styled.h1 `
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 15px;
  @media (max-width: 1300px) {
    padding-top: 10px;
  }
`;
export const PriceContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 15px 0;
  @media (max-width: 1300px) {
    padding: 8px 0;
  }
  width: 100%;
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
export const BeforePrice = styled.p `
  margin: 0;
  color: #d9000d;
  padding-right: 5px;
`;
export const Price = styled.p `
  color: #d9000d;
  margin: 0;
  font-weight: 600;
`;
export const OldPrice = styled.p `
  margin: 0;
  padding-left: 10px;
  text-decoration: line-through;
`;
export const StyledButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  line-height: 42px;
  font-weight: 600;
  font-style: italic;
  ${(props) => props.theme.mixins.mobile(`
    height: 34px; 
    font-size: 13px; 
    line-height: 34px;
  `)};
`;
export const ButtonsWrapper = styled.div `
  display: flex;
  width: 100%;
  > *:first-child {
    width: 100%;
  }
  > *:not(:first-child) {
    max-width: 70px;
    margin-left: 10px;
    border: 1px solid #a0a0a0;
    @media (max-width: 1250px) and (min-width: 1101px) {
      max-width: 50px;
    }
  }
`;
export const ContentContainer = styled.div ``;
export const ProductImage = styled.img `
  object-fit: contain;
  width: 100%;
`;
